<mat-toolbar [@slideInTop]="!slideTop()" [@slideInBottom]="slideTop()"  #header class="header" [ngClass]="customHeaderClasses ?? []" *transloco="let translate;">
  <div class="flex flex-row items-center justify-between  flex-grow">
    <!--logo part -->
    <a [routerLink]="'/'">
      <h1 class="cursor-pointer text-3xl font-semibold"> 
        <!-- 'SpartanJob. -->
      <img src="assets/images/logo/Black _ogo - no_background192x180.png" class="h-18 w-20">  
      </h1>
    </a>
    <!--nav items part -->
    <nav>
    <ul [ngClass]="navOpened() ? ['navbar-menu-list', 'opened'] : 'navbar-menu-list' ">
      <!-- login item for mobile -->
      <ng-template appAuthenticatedDirective [logged]="false">
        <li class="nav-item lg:hidden">
          <span class=" lg:hidden"><mat-icon class="  align-bottom  mr-4" >login</mat-icon> <a [routerLink]="loginUrl" [href]="loginUrl">{{translate('login')}}</a></span>
        </li>
      </ng-template>

      @for(navItem of navItems; track navItem ){
        @switch (navItem.type) {

          @case ('basic') {
            
            <li  [class.active-item]="activeItem(navItem)" class="nav-item" [ngClass]="navItem?.mobileOnly ? ['block','lg:hidden']:[]" (click)="onHeaderItemClicked({navItem},$event);">
              <mat-icon class=" lg:hidden align-bottom  mr-4" >{{navItem.icon}}</mat-icon>

              @if(navItem.id ==='client_portal'){
                  <a class="cursor-pointer" [href]="navItem?.link ?? '#'" >{{translate(navItem.title ?? 'no_title')}}</a>

              } @else {
                  <a  [routerLink]="navItem?.link" [href]="navItem?.link ?? '#'" >{{translate(navItem.title ?? 'no_title')}}</a>
                  }
            </li>
          }

          @case ('collapsable') {

            <li [class.active-item]="activeItem(navItem)" class="nav-item" [ngClass]="navItem?.mobileOnly ? ['block','lg:hidden']:[]" [matMenuTriggerFor]="menu">
              <mat-icon class=" lg:hidden align-bottom mr-4" >{{navItem.icon}}</mat-icon>
              <a class="cursor-pointer">{{translate(navItem.title ?? 'no_title')}}</a>            
            </li>
          
            <mat-menu #menu="matMenu" class="nav-menu">
              <nav>
              <ul class="sub-menu" >
                @for(subItem of navItem?.children; track subItem){
                  <li [class.active-item]="activeItem(subItem)" class="nav-item nav-sub-item" >
                    <a mat-menu-item [routerLink]="subItem?.link ?? '#'"[href]="subItem?.link ?? '#'">{{translate(subItem.title ?? 'no_title')}}</a>
                  </li>
                }
              </ul>
            </nav>
            </mat-menu>
          }
        }
      }
    </ul>
    </nav>
  </div>



  <!-- buttons part -->
  <ng-template appAuthenticatedDirective [logged]="false">
    <!--login button part -->
    <button mat-flat-button class="login-but custom-button" (click)="signIn()" [@fadeIn]="true">
      {{translate('login')}}
    </button>
    <!-- recruiter area button -->
    <a matRipple class="custom-button recruiter-area" [href]="recruiterUrl" target="_blank">
      {{translate('login_recruiter')}}
    </a>
  </ng-template>


  <!-- profile button menu  -->
  <!-- icon -->
  @if(isLogged()){
  <div class="profile-button" [matMenuTriggerFor]="menu">
    <!-- TODO tobe removed <img src="assets/icons/avatar.png" class="h-5 w-5"> -->
    <mat-icon>account_circle</mat-icon>
  </div>
  }

  <!-- menu -->
  <mat-menu [xPosition]="'before'" #menu="matMenu" class="menu">
    <button mat-menu-item>
      <span class="flex flex-col leading-none">
        <span class="menu-text">{{translate('signed_in_as')}}</span>
        <span class="mt-1.5 text-base font-semibold text-slate-800">{{user?.email}}</span>
      </span>
    </button>
    <mat-divider class="border-t-slate-100 my-2"></mat-divider>
    <button mat-menu-item (click)="navProfile()">
      <mat-icon class="text-[#94a3b8]">account_circle</mat-icon>
      <span class="menu-text">{{translate('profile')}}</span>
    </button>
    <mat-divider class="border-t-slate-100 my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
      <mat-icon class="text-[#94a3b8]">logout</mat-icon>
      <span class="menu-text">{{translate('sign_out')}}</span>
    </button>
  </mat-menu>


  <!-- mobile menu / close icon -->
  <!-- TODO tobe removed <img [src]="navOpened() ? 'assets/icons/close.png' : 'assets/icons/menu.png'"
    class="flex cursor-pointer md:hidden h-7 w-7" (click)="handleNav()"> -->
    <mat-icon class="flex cursor-pointer scale-125 py-0.5 lg:hidden h-7 w-7" (click)="handleNav()" >{{navOpened() ? 'close' : 'menu'}}</mat-icon>
</mat-toolbar>

<div class="relative flex flex-col w-full h-full">

    <!-- Dismiss button  -->
    <!-- ### styling attribut ###
      dismissible ==> style dismissible container
      dismissibleButton ==> style dismissible button -->

    @if(data.dismissible){
        <ng-container *ngIf="data.dismissible">
            <div 
            class="absolute top-0 right-0 "
            [ngClass]="data.styles?.dismissible ?getClasses(data.styles.dismissible) :''"

            title
            >
                <button
                [ngClass]="data.styles?.dismissibleButton ?getClasses(data.styles?.dismissibleButton?.button) :''"
                    mat-icon-button
                    [ngStyle]="{'color': '#fff !important'}"

                    [matDialogClose]="undefined">
                    <mat-icon [style.color]="data.styles?.dismissibleButton?.color ? data.styles?.dismissibleButton?.color :''" [ngStyle]="data.styles?.dismissibleButton?.color ? {'color':data.styles?.dismissibleButton?.color} : {'color':'red'}"

                        class="text-secondary "
                        >close</mat-icon>
                </button>
            </div>
        </ng-container>
    }
    

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 "
    [ngClass]="data.styles?.content ? getClasses(data.styles.content):''"
    >
        
        <!-- Icon -->
        @if(data.icon?.show){
            <ng-container >
                <div
                    class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full"
                    [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': data.icon?.color === 'primary',
                                'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600': data.icon?.color === 'accent',
                                'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data.icon?.color === 'warn',
                                'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600': data.icon?.color === 'basic',
                                'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600': data.icon?.color === 'info',
                                'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500': data.icon?.color === 'success',
                                'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500': data.icon?.color === 'warning',
                                'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data.icon?.color === 'error'
                                }">
                    <mat-icon 
                        class="text-current">{{data.icon?.name ??''}}</mat-icon>
                </div>
            </ng-container>
        }
    
        @if(data.title || data.message){
            <ng-container>
                <div class="flex flex-col items-center sm:items-start mt-0 sm:mt-0  space-y-1 text-center sm:text-left">
    
                    <!-- Title -->
                    @if(data.title){
                        <ng-container>
                            <div
                                class="text-xl leading-6 font-medium"
                                [ngClass]="data.styles?.title ? getClasses(data.styles.title):''"
                                [innerHTML]="domSanitizer.bypassSecurityTrustHtml(data.title)"></div>
                        </ng-container>
                    }
                    
    
                    <!-- Message -->
                    @if(data.message){
                        <ng-container >
                            <div
                                class="text-secondary"
                                [ngClass]="data.styles?.message ? getClasses(data.styles.message):''"
                                [innerHTML]="domSanitizer.bypassSecurityTrustHtml(data.message)"></div>
                        </ng-container>
                    }
                  
                </div>
            </ng-container>
        }
    

    </div>

    <!-- Actions -->
    @if(data.actions?.confirm?.show || data.actions?.cancel?.show){
        <ng-container >

            <div class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
    
                <!-- Cancel -->
                @if(data.actions?.cancel?.show){
                    <ng-container>
                        <button
                            mat-stroked-button
                            [matDialogClose]="'cancelled'">
                            {{data.actions?.cancel?.label}}
                        </button>
                    </ng-container>
                }
               
    
                <!-- Confirm -->

        @if(data.actions?.confirm?.show){
            <ng-container>
                <button
                    mat-flat-button
                    [color]="data.actions?.confirm?.color"
                    [matDialogClose]="'confirmed'">
                    {{data.actions?.confirm?.label}}
                </button>
            </ng-container>
        }
                
    
            </div>
        </ng-container>
    }
    

</div>
import { NgClass, NgIf,NgOptimizedImage, NgStyle } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PricesPopupConfig } from './prices-popup-config';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-prices-popup',
  standalone: true,
  imports      : [NgIf, MatButtonModule, MatDialogModule, MatIconModule, NgClass,NgOptimizedImage,NgStyle],
  templateUrl: './prices-popup.component.html',
  styleUrl: './prices-popup.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PricesPopupComponent {
  /**
   * Constructor
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: PricesPopupConfig,
          public domSanitizer:DomSanitizer)
  {
  }
  getClasses(classes): string {
    return classes.join(' ');
  }

}

import { NavigationItemModel } from 'app/core/models/navigation-item.model';
import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, Signal, computed, inject, signal } from '@angular/core';
import { SvgComponent } from 'app/shared/svg-component/svg-component.component';
import { Store } from '@ngrx/store';
import { selectAuthentication } from 'app/store/reducers';
import { Subject, takeUntil, tap } from 'rxjs';
import { AuthenticationState } from 'app/store/states/authentication.state';
import { footerItems } from 'app/core/data/navigation-data';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterLink } from '@angular/router';



@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [SvgComponent,NgOptimizedImage,TranslocoModule,RouterLink],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  private _store = inject(Store);
  private _unsubscribeAll : Subject<void> = new Subject<void>();
  currentYear = new Date().getFullYear();
  footerNavItems : Signal<NavigationItemModel[]> = signal([]);
  isLogged = signal(false);
  spartanjob = 'SpartanJob.'
  socialIcons : {svgName:string,svgLink?:string}[]= [
    {
      svgName:'facebook.png',
      svgLink:'https://www.facebook.com/profile.php?id=61562533883449',
    },
    {
      svgName:'tiktok.png',
      svgLink:'https://www.tiktok.com/@spartanjob',
    },
    {
      svgName:'instagram.png',
      svgLink:'https://www.instagram.com/spartanjob.ch/',
    },
    {
      svgName:'linkedin.png',
      svgLink:'https://www.linkedin.com/company/spartanjob/?viewAsMember=true',
    }
  ];

  ngOnInit(): void {
    /** auth state listener */
    this.authStateListener();
  }

      /** auth state listener */
      authStateListener(): void {
        this._store.select(selectAuthentication).pipe(
          takeUntil(this._unsubscribeAll),
          tap((authState: AuthenticationState) => {
            /// set is logged and user info
            this.isLogged.set(authState.isLoggedIn);
            /** set footer elements  */
            this.setFooterElements();
          })
        ).subscribe()
      }

    /** set footer elements */
      setFooterElements():void {
      const allFooterElements: NavigationItemModel[] = footerItems;
      this.footerNavItems = computed (()=>{ return this.isLogged() ? allFooterElements : allFooterElements
        .filter((footerElement: NavigationItemModel) => footerElement.privilege === 'public')
        .map((footerElement: NavigationItemModel) => footerElement);})
    }
 
}

<footer class="h-fit w-full" *transloco="let translate">
  <!-- nav items here -->

  <div class="social-footer">
    <ul class="social-footer-container content-custom-width ">
      <!-- logo -->
      <a [routerLink]="'/'"><h1 class="text-3xl font-semibold">{{spartanjob}} </h1></a>

      <!-- website copyright and sitemap -->
      <ul class="social-footer-website-links">
          @for(item of footerNavItems(); track item){
            <a target="_blank" [href]="item?.link"> {{ translate('nav.'+item?.title)}} </a>
          }
      </ul>
      <div >
        <!-- sociale media links -->
        <ul class="social-footer-website-links">
          @for(item of socialIcons; track item){
            <a target="_blank"  [href]="item?.svgLink"> <img class="w-6 h-6" [src]="'assets/images/social/'+item?.svgName"> </a>
          }
        </ul>
        <!-- <a target="_blank" [routerLink]="'/contact-us'"> {{ translate('nav.contact_us')}}</a> -->
        <!-- <a target="_blank" href="#" > Site map </a>
        <a target="_blank" href="#" >© {{currentYear}} Spartan | All rights Reserved</a> -->
      </div>
    </ul>
  </div>
</footer>
